<template>
  <el-main>
    <el-row class="movie-list">
      <el-col :md="20" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>我的应用</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="addAppDialog">添加</el-button>
          </div>
          <el-table
            :data="dataList"
            style="width: 100%"
          >
            <el-table-column
              prop="clientId"
              label="客户端 ID"
            />
            <el-table-column
              prop="resourceIds"
              label="资源 ID"
            />
            <el-table-column
              prop="scope"
              label="作用域"
            />
            <el-table-column
              prop="authorizedGrantTypes"
              label="授权类型"
            />
            <el-table-column
              prop="autoapprove	"
              label="自动授权"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.autoapprove">
                  是
                </span>
                <span v-else>
                  否
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="webServerRedirectUri"
              label="回调地址"
            />
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleDetail(scope.$index, scope.row)"
                >详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog
      title="OAuth 应用"
      append-to-body
      :visible.sync="oauthDialog"
      width="30%"
      center
    >
      <el-form ref="form" :model="appForm">
        <el-form-item label="应用名">
          <el-input v-model="appForm.name" style="margin-left: 5px" />
        </el-form-item>
        <el-form-item label="主页 URL">
          <el-input v-model="appForm.homeUrl" style="margin-left: 5px" />
        </el-form-item>
        <el-form-item label="应用描述">
          <el-input v-model="appForm.description" style="margin-left: 5px" />
        </el-form-item>
        <el-form-item label="回调 URL">
          <el-input v-model="appForm.callbackUrl" style="margin-left: 5px" />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="addApp"
          >确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-main>
</template>

<script>
import { createApp, getOAuth, getOAuthApps, getToken } from '@/api/account'

export default {
  name: 'MyOAuth',
  data() {
    return {
      dataList: [],
      oauthDialog: false,
      appForm: {
        name: ''
      }
    }
  },
  created() {
    document.title = '我的 OAuth 应用'
    getOAuthApps().then(resp => {
      if (resp.code === 0) {
        this.dataList = resp.data
      }
    })
  },
  methods: {
    addAppDialog() {
      this.oauthDialog = true
    },
    addApp() {
      createApp(this.appForm).then(resp => {
        if (resp.code !== 0) {
          this.$notify.warning({
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      })
      this.oauthDialog = false
    }
  }
}
</script>

<style scoped>
</style>

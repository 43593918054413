import { get, post } from '@/utils/request'

const accountApi = {
  checkUsernameApi: '/api/user/account/check/username',
  selectUsernameApi: '/api/user/account/select/username',
  checkEmailApi: '/api/user/account/check/email',
  verifyCodeApi: '/api/auth/code/verify',
  captchaCodeApi: '/api/auth/code/captcha',
  pubkeyApi: '/api/auth/code/pubkey',
  registerApi: '/api/auth/create',
  forgotApi: '/api/auth/forgot',
  loginApi: '/api/auth/signin',
  logoutApi: '/api/auth/signout',
  resetPasswordApi: '/api/account/user/reset',
  accountMyVipApi: '/api/user/vip/my',
  accountVipPlanApi: '/api/user/vip/plan',
  accountVipApi: '/api/user/vip/buy',
  oauthAppApi: '/api/account/oauth/create',
  loginRecordApi: '/api/account/record/list',
  signOutApi: '/api/account/deactivate',
  updateAvatarApi: '/api/file/avatar/update'
}

export function isUsernameExist(username) {
  return get(accountApi.checkUsernameApi + '?username=' + username)
}

export function selectUsername(username) {
  return post(accountApi.selectUsernameApi + '/' + username)
}

export function isEmailExist(email) {
  return get(accountApi.checkEmailApi + '/' + email)
}

export function register(userRegistry) {
  return post(accountApi.registerApi, userRegistry)
}

export function forgot(userRegistry) {
  return post(accountApi.forgotApi, userRegistry)
}

export function resetPassword(resetPasswordData) {
  return post(accountApi.resetPasswordApi, resetPasswordData)
}

// 获取公钥
export function getPubkey(channel) {
  return get(accountApi.pubkeyApi + '?channel=' + channel)
}

// 获取图形验证码
export function getCaptchaCode(captchaCodeApi) {
  return get(accountApi.captchaCodeApi)
}

// 获取短信验证码
export function getVerifyCode(verifyCode) {
  return post(accountApi.verifyCodeApi, verifyCode)
}

// 登录
export function login(loginData) {
  return post(accountApi.loginApi, loginData)
}

// 注销
export function logout() {
  return get(accountApi.logoutApi)
}

export function updateAvatar(userAvatar) {
  return post(accountApi.updateAvatarApi, userAvatar)
}

// VIP
export function getMyVip() {
  return get(accountApi.accountMyVipApi)
}

export function getVipPlans() {
  return get(accountApi.accountVipPlanApi)
}

export function vip(data) {
  return post(accountApi.accountVipApi, data)
}

export function createApp(data) {
  return post(accountApi.oauthAppApi, data)
}

export function getOAuthApps() {
  return get('/api/account/oauth/list')
}

export function getLoginRecord() {
  return get(accountApi.loginRecordApi)
}

export function signOut(loginId) {
  return post(accountApi.signOutApi + '/' + loginId)
}
